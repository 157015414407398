import { and } from 'ramda'
import { isNotNil } from './webHelper'

/**
 * 產生單位顯示的 key 值
 */
type Props = {
  unitType?: number
  unitSeq?: number
  originUnitType?: number
  originUnitSeq?: number
}

// function genUnitKey({ unitType, unitSeq }: Props) {
//   return and(isNotNil(unitType), isNotNil(unitSeq))
//     ? `${unitType}_${unitSeq}`
//     : undefined
// }

function genUnitKey(unitType?: number | null, unitSeq?: number | null) {
  if (and(isNotNil(unitType), isNotNil(unitSeq))) {
    return `${unitType}_${unitSeq}`
  }
  return undefined
}

export default genUnitKey
